import React from "react";

import { Apartment, AppConfig } from "../types/Config.types";

import "./Apartment.scss";

import floorPlan from "../assets/floor-layout.png";
import logoIconDark from "../assets/icon-3d-dark.svg";
import logoIconLight from "../assets/icon-3d-light.svg";
import iconBalconLight from "../assets/icon-balcon-light.svg";
import iconBalconDark from "../assets/icon-balcon-dark.svg";
import iconBuildingLight from "../assets/icon-building-light.svg";
import iconBuildingDark from "../assets/icon-building-dark.svg";

import Viewer3d, { Viewer3dStateExternal, Viewer3dApartmentAvailability } from "./Viewer3d";

interface ApartmentProps {
    apartmentId: string;
    apartment: Apartment;
    config: AppConfig;
    close: () => void;
    changeApartment: (apartmentId: string) => void;
}

type MainViewType = "VirtualTour" | "BuildingView" | "DollHouseView" | "Balcony";

interface ApartmentState {
    viewType: MainViewType;
    apartmentId: string;
    currentApartmentId: string | undefined;
    externalStateBuilding: Viewer3dStateExternal;
    externalStateDollHouse: Viewer3dStateExternal;
}

export default class ApartmentView extends React.Component<ApartmentProps, ApartmentState> {
    state: ApartmentState = {
        viewType: "DollHouseView",
        apartmentId: this.props.apartmentId,
        currentApartmentId: undefined,
        externalStateBuilding: {
            currentViewId: this.props.apartment.view.id,
            currentTransitionId: undefined,
            futureViewId: undefined,
            currentIndex: this.props.apartment.view.frame,
            rotateActive: false,
            rotateStartIndex: undefined
        },
        externalStateDollHouse: {
            currentViewId: this.props.apartment.dollHouse.id,
            currentTransitionId: undefined,
            futureViewId: undefined,
            currentIndex: this.props.apartment.dollHouse.frame,
            rotateActive: false,
            rotateStartIndex: undefined
        }
    };

    render() {
        return (
            <div className="apartment-view">
                <div className="apartment-view__left">
                    {this.state.viewType === "Balcony" ? (
                        <iframe src={this.props.apartment.view3dbalcony} className="apartment-view__left-iframe" />
                    ) : null}
                    {this.state.viewType === "VirtualTour" ? (
                        <iframe src={this.props.apartment.view3d} className="apartment-view__left-iframe" />
                    ) : null}
                    {this.state.viewType === "BuildingView" && this.buildingView ? (
                        <Viewer3d
                            views={[this.buildingView]}
                            transitions={[]}
                            setCurrentApartmentId={f => {
                                this.setState(p => ({
                                    currentApartmentId: f(p.currentApartmentId)
                                }));
                            }}
                            viewportConfig={this.props.config.viewport}
                            navigation={[]}
                            currentApartmentId={this.state.currentApartmentId}
                            apartmentIds={this.props.config.apartments.reduce((o, a) => {
                                o[a.id] = true;
                                return o;
                            }, {} as { [key: string]: true })}
                            openApartment={apartmentId => this.changeApartment(apartmentId)}
                            highlightApartmentId={this.state.apartmentId}
                            externalState={this.state.externalStateBuilding}
                            externalStateSet={f =>
                                new Promise(res =>
                                    this.setState(
                                        p => ({ externalStateBuilding: f(p.externalStateBuilding) }),
                                        () => res()
                                    )
                                )
                            }
                            apartmentsAvailability={this.props.config.apartments.reduce((o, a) => {
                                o[a.id] = a.availability;
                                return o;
                            }, {} as Viewer3dApartmentAvailability)}
                            hideElementsSwitch={true}
                        />
                    ) : null}
                    {this.state.viewType === "DollHouseView" && this.dollHouseView ? (
                        <Viewer3d
                            views={[this.dollHouseView]}
                            transitions={[]}
                            setCurrentApartmentId={() => {}}
                            viewportConfig={this.props.config.viewport}
                            navigation={[]}
                            currentApartmentId={undefined}
                            apartmentIds={{}}
                            openApartment={() => {}}
                            hideNightSwitch={true}
                            hideOrientation={true}
                            externalState={this.state.externalStateDollHouse}
                            externalStateSet={f =>
                                new Promise(res =>
                                    this.setState(
                                        p => ({ externalStateDollHouse: f(p.externalStateDollHouse) }),
                                        () => res()
                                    )
                                )
                            }
                            apartmentsAvailability={{}}
                            hideElementsSwitch={true}
                            upperHalfRotateInverse={true}
                            inverseAllMovements={true}
                        />
                    ) : null}

                    <div className="apartment-view__left-buttons">
                        <div
                            className={`apartment-view__left-button ${
                                this.state.viewType === "Balcony" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewType: "Balcony" })}
                        >
                            <div className="apartment-view__left-button-logo">
                                <img src={iconBalconDark} className="apartment-view__left-button-logo-img dark" />
                                <img src={iconBalconLight} className="apartment-view__left-button-logo-img light" />
                            </div>
                            <div className="apartment-view__left-button-label">
                                WIDOK <br />Z BALKONU
                            </div>
                        </div>
                        <div
                            className={`apartment-view__left-button ${
                                this.state.viewType === "VirtualTour" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewType: "VirtualTour" })}
                        >
                            <div className="apartment-view__left-button-logo">
                                <img src={logoIconDark} className="apartment-view__left-button-logo-img dark" />
                                <img src={logoIconLight} className="apartment-view__left-button-logo-img light" />
                            </div>
                            <div className="apartment-view__left-button-label">
                                Spacer <br />W Mieszkaniu
                            </div>
                        </div>
                        <div
                            className={`apartment-view__left-button ${
                                this.state.viewType === "DollHouseView" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewType: "DollHouseView" })}
                        >
                            <div className="apartment-view__left-button-icon">zoom_out_map</div>
                            <div className="apartment-view__left-button-label">
                                Widok <br />
                                Dollhouse
                            </div>
                        </div>
                        <div
                            className={`apartment-view__left-button ${
                                this.state.viewType === "BuildingView" ? "is-active" : ""
                            }`}
                            onClick={() => this.setState({ viewType: "BuildingView" })}
                        >
                            <div className="apartment-view__left-button-logo">
                                <img src={iconBuildingDark} className="apartment-view__left-button-logo-img dark" />
                                <img src={iconBuildingLight} className="apartment-view__left-button-logo-img light" />
                            </div>
                            <div className="apartment-view__left-button-label">
                                Widok <br />
                                Budynku
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apartment-view__right">
                    <div className="apartment-view__right-content">
                        <div className="apartment-view__header">
                            <div className="apartment-view__header-code">{this.apartment.name}</div>
                            <div className="apartment-view__header-label">Mieszkanie</div>
                            <div className="apartment-view__header-icon" onClick={() => this.props.close()}>
                                close
                            </div>
                        </div>
                        <div className="apartment-view__info-availability">
                            {this.apartment.availability === 1
                                ? "Dostępne"
                                : this.apartment.availability === 2
                                ? "Rezerwacja"
                                : "Sprzedane"}
                        </div>
                        <div className="apartment-view__info-row">
                            <div className="apartment-view__info big">
                                <div className="apartment-view__info-label">powierzchnia</div>
                                <div className="apartment-view__info-value">
                                    {this.apartment.apartmentArea} m<sup>2</sup>
                                </div>
                            </div>

                            <div className="apartment-view__info right">
                                <div className="apartment-view__info-label">kod mieszkania</div>
                                <div className="apartment-view__info-value">{this.apartment.name}</div>
                            </div>
                        </div>

                        <div className="apartment-view__info-box">
                            <div className="apartment-view__info-box-tiles">
                                <div className="apartment-view__info-box-tile">
                                    Budynek <b>{this.apartment.building}</b>
                                </div>
                                <div className="apartment-view__info-box-tile">
                                    Piętro <b>{this.apartment.floor}</b>
                                </div>
                                <div className="apartment-view__info-box-tile">
                                    Pokoje <b>{this.apartment.roomCount}</b>
                                </div>
                            </div>

                            <div className="apartment-view__info-box-lines">
                                <div className="apartment-view__info-box-line">
                                    Taras o powierzchni 21 m<sup>2</sup>
                                </div>
                                <div className="apartment-view__info-box-line">
                                    Przestonna kuchnia doświetlona z dwóch stron
                                </div>
                                <div className="apartment-view__info-box-line">Dźwiękoszczelne okna</div>
                                <div className="apartment-view__info-box-line">
                                    Świetne nasłonecznienie - południowo-zachodnia orientacja
                                </div>
                            </div>
                        </div>

                        <div className="apartment-view__scrollable">
                            <div className="apartment-view__info-row">
                                <div className="apartment-view__info-areas">
                                    <div className="apartment-view__info-areas-title">Metraż pomieszczeń:</div>

                                    <div className="apartment-view__info-area">
                                        <div className="apartment-view__info-area-label">Przedpokój</div>
                                        <div className="apartment-view__info-area-value">
                                            9,09m<sup>2</sup>
                                        </div>
                                    </div>

                                    <div className="apartment-view__info-area">
                                        <div className="apartment-view__info-area-label">Łazienka</div>
                                        <div className="apartment-view__info-area-value">
                                            5,34<sup>2</sup>
                                        </div>
                                    </div>

                                    <div className="apartment-view__info-area">
                                        <div className="apartment-view__info-area-label">Pokój Dzienny</div>
                                        <div className="apartment-view__info-area-value">
                                            17,09m<sup>2</sup>
                                        </div>
                                    </div>

                                    <div className="apartment-view__info-area">
                                        <div className="apartment-view__info-area-label">Sypialnia</div>
                                        <div className="apartment-view__info-area-value">
                                            12,09m<sup>2</sup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="apartment-view__floor-layout">
                                <img src={floorPlan} className="apartment-view__floor-layout-img" />
                            </div>
                        </div>
                        <div className="apartment-view__buttons">
                            <button className="button small is-active">
                                <i className="icon">favorite</i>Dodaj do ulubionych
                            </button>
                            <button className="button small">Wyślij mailem</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get buildingView() {
        const view = this.props.config.views.find(v => v.id === this.apartment.view.id);
        return view;
    }

    get dollHouseView() {
        return this.props.config.views.find(v => v.id === this.apartment.dollHouse.id);
    }

    get apartment() {
        const apartment = this.props.config.apartments.find(a => a.id === this.state.apartmentId);

        if (!apartment) {
            return this.props.config.apartments[0];
        }

        return apartment;
    }

    private changeApartment(apartmentId: string) {
        const apartment = this.props.config.apartments.find(a => a.id === apartmentId);

        if (!apartment) {
            return;
        }

        this.setState(p => ({
            apartmentId: apartmentId,
            externalStateBuilding: {
                ...p.externalStateBuilding,
                currentViewId: apartment.view.id,
                currentIndex:
                    p.externalStateBuilding.currentViewId !== apartment.view.id
                        ? apartment.view.frame
                        : p.externalStateBuilding.currentIndex,
                currentTransitionId: undefined,
                futureViewId: undefined
            },
            externalStateDollHouse: {
                ...p.externalStateDollHouse,
                currentViewId: apartment.dollHouse.id,
                currentIndex: apartment.dollHouse.frame,
                currentTransitionId: undefined,
                futureViewId: undefined
            }
        }));
    }
}
