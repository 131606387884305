import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

declare global {
    const API_CONFIG: {
        apiUrl: string;
        landingPageUrl: string;
    };
}

ReactDOM.render(<App />, document.getElementById("root"));
