import React from "react";
import { Apartment } from "../types/Config.types";

import "./ApartmentList.scss";

interface ApartmentListProps {
    apartments: Apartment[];
    openApartment: (apartmentId: string) => void;
    onApartmentOver: (apartmentId: string) => void;
    onApartmentOut: (apartmentId: string) => void;
    currentOverApartment: string | undefined;
    isCards: boolean;
}

export default class ApartmentList extends React.Component<ApartmentListProps> {
    render() {
        if (this.props.isCards) {
            return (
                <div className="apartment-list">
                    <div className="apartment-list__cards">
                        {this.props.apartments.map(apartment => (
                            <div
                                className={`apartment-list__card ${
                                    apartment.id === this.props.currentOverApartment ? "is-hover" : ""
                                }`}
                                key={apartment.id}
                                onClick={() => this.props.openApartment(apartment.id)}
                                onMouseOver={() => this.props.onApartmentOver(apartment.id)}
                                onMouseOut={() => this.props.onApartmentOut(apartment.id)}
                            >
                                <div className="apartment-list__card-content">
                                    <div className="apartment-list__card-title">{apartment.name}</div>
                                    <div className="apartment-list__card-availability">
                                        {apartment.availability === 1
                                            ? "Dostępne"
                                            : apartment.availability === 2
                                            ? "Rezerwacja"
                                            : "Sprzedane"}
                                    </div>
                                    <div className="apartment-list__card-row">
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Powierzchnia</div>
                                            <div className="apartment-list__card-prop-value">
                                                {apartment.apartmentArea} m<sup>2</sup>
                                            </div>
                                        </div>
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Liczba Pokoi</div>
                                            <div className="apartment-list__card-prop-value">{apartment.roomCount}</div>
                                        </div>
                                    </div>
                                    <div className="apartment-list__card-row">
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Budynek</div>
                                            <div className="apartment-list__card-prop-value">{apartment.building}</div>
                                        </div>
                                        <div className="apartment-list__card-prop">
                                            <div className="apartment-list__card-prop-label">Piętro</div>
                                            <div className="apartment-list__card-prop-value">{apartment.floor}</div>
                                        </div>
                                    </div>
                                    <div className="apartment-list__card-layout">
                                        <img src={apartment.layout} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <div className="apartment-list with-table">
                <table className="apartment-list__table">
                    <thead>
                        <tr>
                            <th>Mieszkanie</th>
                            <th>Budynek</th>
                            <th>Pow</th>
                            <th>Piętro</th>
                            <th>Liczba Pokoi</th>
                            {/* <th>Status</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.apartments.map(apartment => (
                            <tr
                                key={apartment.id}
                                onClick={() => this.props.openApartment(apartment.id)}
                                className={`${apartment.id === this.props.currentOverApartment ? "is-hover" : ""}`}
                                onMouseOver={() => this.props.onApartmentOver(apartment.id)}
                                onMouseOut={() => this.props.onApartmentOut(apartment.id)}
                            >
                                <td>{apartment.name}</td>
                                <td>{apartment.building}</td>
                                <td>{apartment.apartmentArea}</td>
                                <td>{apartment.floor}</td>
                                <td>{apartment.roomCount}</td>
                                {/* <td>
                                    {apartment.availability === 1
                                        ? "Dostępne"
                                        : apartment.availability === 2
                                        ? "Rezerwacja"
                                        : "Sprzedane"}
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
