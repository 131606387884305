import React from "react";

import "./Start.scss";

import logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";
import { ROUTER } from "./ConfigLoader";

export default class Start extends React.Component {
    render() {
        return (
            <div className="start">
                <img src={logo} className="start__logo" />
                <div className="start__fake-left"></div>
                <div className="start__video">
                    <video className="start__video-video" autoPlay={true} muted={true} loop={true}>
                        <source src="start-video.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="start__nav">
                    <div className="start__nav-content">
                        <div className="start__nav-title">
                            Osiedle
                            <br />
                            Złota Ostoja
                            <br />
                            <div className="start__nav-title-small">Etap 3</div>
                        </div>

                        <div className="start__nav-caption">
                            Przestrzeń&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;możliwości
                        </div>
                        {/* <div className="start__nav-logo">
                        <img src={logo} className="start__nav-logo-img" alt="" />
                    </div> */}
                        <div className="start__nav-links">
                            <div className="start__nav-link">Lokalizacja</div>
                            <NavLink to={ROUTER.Apartments} className="start__nav-link">
                                Mieszkania
                            </NavLink>

                            <div className="start__nav-link">O Inwestycji</div>
                            <div className="start__nav-link">Galeria</div>
                            <div className="start__nav-link">Porównaj</div>
                        </div>
                        <div className="start__nav-disclaimer">Demo technologiczne 3D Estate Sp. z o.o.</div>
                    </div>
                </div>
            </div>
        );
    }
}
