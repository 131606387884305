import React from "react";

import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { ROUTER } from "./ConfigLoader";

import "./Nav.scss";

import logo from "../assets/logo.png";

interface NavProp {
    close?: () => void;
}

class Nav extends React.Component<NavProp & RouteComponentProps> {
    render() {
        return (
            <div className="nav">
                <div className="nav__container">
                    <div className="nav__close" onClick={() => !!this.props.close && this.props.close()}>
                        close
                    </div>
                    {/* <div className="nav__logo">
                        <img
                            src={logo}
                            className="nav__logo-img"
                            onClick={() => this.props.history.push(ROUTER.Start)}
                        />
                    </div> */}
                    <div className="nav__links">
                        <NavLink to={ROUTER.Start} className="nav__link">
                            Start
                        </NavLink>
                        <div className="nav__link">Lokalizacja</div>
                        <NavLink to={ROUTER.Apartments} className="nav__link">
                            Apartamenty
                        </NavLink>

                        <div className="nav__link">O Inwestycji</div>
                        <div className="nav__link">Galeria</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Nav);
