import React from "react";
import axios, { AxiosResponse } from "axios";
import { AppConfig } from "../types/Config.types";

import { Switch, Route, Redirect } from "react-router-dom";

import Nav from "./Nav";
import Start from "./Start";
import About from "./About";

import Apartments from "./Apartments";

import "./ConfigLoader.scss";

export const ROUTER = {
    Start: "/Start",
    Apartments: "/Apartments"
};

interface ConfigLoaderState {
    appConfig: AppConfig | undefined;
    state: "idle" | "pending" | "completed" | "error";
    images: HTMLImageElement[];
    lightVersion?: boolean;
}

export default class ConfigLoader extends React.Component<{}, ConfigLoaderState> {
    state: ConfigLoaderState = {
        appConfig: undefined,
        state: "idle",
        images: [],
        lightVersion: true
    };

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);

        if (params.get("light") === "on") {
            localStorage.setItem("3dlight", "on");
        } else if (params.get("light") === "off") {
            localStorage.setItem("3dlight", "off");
        }

        if (localStorage.getItem("3dlight") === "on") {
            this.getConfig(true);
        } else {
            this.getConfig();
        }
    }

    render() {
        if (this.state.state === "idle" || this.state.state === "pending") {
            return (
                <div className="config-loader">
                    <div className="config-loader__title">Ładowanie konfiguracji...</div>
                </div>
            );
        }

        if (this.state.state === "error" || !this.state.appConfig) {
            return (
                <div className="config-loader">
                    <div className="config-loader__title">Błąd podczas ładowania konfiguracji</div>
                    <button className="button" onClick={() => this.getConfig()}>
                        Reload config
                    </button>
                </div>
            );
        }

        if (this.totalImagesToLoad === 0) {
            return (
                <div className="config-loader">
                    <div className="config-loader__title">Configuration has no views</div>
                    <button className="button" onClick={() => this.getConfig()}>
                        Reload config
                    </button>
                </div>
            );
        }

        if (this.totalImagesLoaded < this.totalImagesToLoad) {
            return (
                <div className="config-loader">
                    <div className="config-loader__title">
                        Ładowanie aplikacji... {((this.totalImagesLoaded * 100) / this.totalImagesToLoad).toFixed(0)}%
                    </div>
                </div>
            );
        }
        return (
            <div className="application">
                <Switch>
                    <Route path={ROUTER.Start}>
                        <Start />
                    </Route>
                    <Route path={ROUTER.Apartments}>
                        <Apartments config={this.state.appConfig} />
                        {/* <NewRotate appConfig={this.state.appConfig} /> */}
                    </Route>
                    <Redirect from="*" to={ROUTER.Start} />
                </Switch>
            </div>
        );
    }

    get totalImagesToLoad(): number {
        if (this.state.state === "completed" && !!this.state.appConfig) {
            return (
                this.state.appConfig.views.reduce((sum, view) => sum + view.frames.length * 2, 0) +
                this.state.appConfig.transitions.reduce((sum, transition) => sum + transition.frames.length * 2, 0)
            );
        }
        return 0;
    }

    get totalImagesLoaded(): number {
        return this.state.images.length;
    }

    private async getConfig(light?: boolean) {
        try {
            this.setState({ state: "pending" });
            const response: AxiosResponse<AppConfig> = light
                ? await axios({ url: "/assets/config-light.json" })
                : await axios({ url: "/assets/config.json" });
            const response2: AxiosResponse<AppConfig> = await axios({ url: "/assets/config-apartments.json" });
            const appConfig = { ...response.data, ...response2.data };

            if (!appConfig.views || !appConfig.transitions) {
                this.setState({ state: "error" });
                return;
            }

            this.setState(
                {
                    appConfig: appConfig,
                    state: "completed"
                },
                () => this.loadImages()
            );
        } catch (e) {
            this.setState({ state: "error" });
        }
    }

    private loadImages() {
        if (!this.state.appConfig) {
            return;
        }

        this.state.appConfig.views.forEach(view => {
            view.frames.forEach(frame => {
                const i_d = new Image();
                i_d.src = frame.src_d;
                i_d.onload = () => {
                    this.setState(p => {
                        p.images.push(i_d);
                        return p;
                    });
                };

                const i_n = new Image();
                i_n.src = frame.src_n;
                i_n.onload = () =>
                    this.setState(p => {
                        p.images.push(i_n);
                        return p;
                    });
            });
        });

        this.state.appConfig.transitions.forEach(transition => {
            transition.frames.forEach(frame => {
                const i_d = new Image();
                i_d.src = frame.src_d;
                i_d.onload = () => {
                    this.setState(p => {
                        p.images.push(i_d);
                        return p;
                    });
                };

                const i_n = new Image();
                i_n.src = frame.src_n;
                i_n.onload = () =>
                    this.setState(p => {
                        p.images.push(i_n);
                        return p;
                    });
            });
        });
    }
}
