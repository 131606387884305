import { View, Transition } from "../types/Config.types";

export function distanceBetweenTwoIndexes(from: number, to: number, arrayLength: number): number {
    const distance = to - from;

    if (Math.abs(distance) > arrayLength / 2) {
        if (distance < 0) {
            return arrayLength + distance;
        } else {
            return distance - arrayLength;
        }
    }

    return distance;
}

export function distanceBetweenViewAndTransition(view: View, transition: Transition, current: number): number {
    return Math.abs(
        distanceBetweenTwoIndexes(
            current,
            transition.to.id === view.id ? transition.to.frameid : transition.from.frameid,
            view.frames.length
        )
    );
}

export function findClosestTransition(view: View, transitions: Transition[], current: number): Transition {
    transitions.sort((a, b) =>
        distanceBetweenViewAndTransition(view, a, current) < distanceBetweenViewAndTransition(view, b, current) ? -1 : 1
    );
    // transitions.forEach(t => console.log(distanceBetweenViewAndTransition(view, t, current)));

    return transitions[0];
}

export function findMatchingTransitions(currentView: View, newView: View, transitions: Transition[]): Transition[] {
    return transitions.filter(
        e =>
            (e.to.id === currentView.id && e.from.id === newView.id) ||
            (e.to.id === newView.id && e.from.id === currentView.id)
    );
}

export function isBetween(value: number, min: number | undefined, max: number | undefined): boolean {
    return (min === undefined || min <= value) && (max === undefined || max >= value);
}
