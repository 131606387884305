import React from "react";

import Switch from "../form/Switch";
import Range from "../form/Range";

import "./ApartmentFilter.scss";

import { Filters } from "./Apartments";
import Check from "../form/Check";
import { ApartmentPropDefinition } from "../types/Config.types";

interface ApartmentFilterProps {
    init: Filters;
    current: Filters;
    filtersByPropsBoolean: number[];
    filtersByPropsBooleanList: ApartmentPropDefinition[];
    filtersByPropsBooleanChange: (filter: number) => void;
    changeFilter: <T extends keyof Filters>(filter: T, value: Filters[T]) => void;
}

export default class ApartmentFilter extends React.Component<ApartmentFilterProps> {
    render() {
        return (
            <div className="apartment-filter">
                <div className="apartment-filter__column">
                    {this.props.filtersByPropsBooleanList.map(filter => (
                        <Check
                            key={filter.id}
                            value={this.props.filtersByPropsBoolean.indexOf(filter.id) !== -1}
                            onChange={e => this.props.filtersByPropsBooleanChange(filter.id)}
                            label={filter.name}
                        />
                    ))}

                    <div className="apartment-filter__column-spacer"></div>
                    <Check
                        value={this.props.current.available}
                        onChange={e => this.props.changeFilter("available", e)}
                        label="dostępne"
                    />
                    <Check
                        value={this.props.current.reserved}
                        onChange={e => this.props.changeFilter("reserved", e)}
                        label="rezerwacja"
                    />
                    <Check
                        value={this.props.current.sold}
                        onChange={e => this.props.changeFilter("sold", e)}
                        label="sprzedane"
                    />
                    <div className="apartment-filter__column-spacer"></div>

                    <Check
                        value={this.props.current.showOnlyVisible}
                        onChange={e => this.props.changeFilter("showOnlyVisible", e)}
                        label="Tylko widoczne apartamenty"
                    />
                </div>
                <div className="apartment-filter__column">
                    <Range
                        value={undefined}
                        label="Powierzchnia"
                        valueMin={this.props.current.areaMin}
                        valueMax={this.props.current.areaMax}
                        min={this.props.init.areaMin}
                        max={this.props.init.areaMax}
                        setValueMin={v => this.props.changeFilter("areaMin", v)}
                        setValueMax={v => this.props.changeFilter("areaMax", v)}
                        onChange={() => {}}
                    />
                    <Range
                        value={undefined}
                        label="Piętro"
                        valueMin={this.props.current.floorMin}
                        valueMax={this.props.current.floorMax}
                        min={this.props.init.floorMin}
                        max={this.props.init.floorMax}
                        setValueMin={v => this.props.changeFilter("floorMin", v)}
                        setValueMax={v => this.props.changeFilter("floorMax", v)}
                        onChange={() => {}}
                    />
                    <Range
                        value={undefined}
                        label="Liczba pokoi"
                        valueMin={this.props.current.roomCountMin}
                        valueMax={this.props.current.roomCountMax}
                        min={this.props.init.roomCountMin}
                        max={this.props.init.roomCountMax}
                        setValueMin={v => this.props.changeFilter("roomCountMin", v)}
                        setValueMax={v => this.props.changeFilter("roomCountMax", v)}
                        onChange={() => {}}
                    />
                </div>

                {/* <Switch
                    value={this.props.current.showOnlyVisible}
                    onChange={e => this.props.changeFilter("showOnlyVisible", e)}
                    label="Tylko widoczne apartamenty"
                    labelFalse="Nie"
                    labelTrue="Tak"
                /> */}
            </div>
        );
    }
}
