import React from "react";
import { AppConfig, View, Transition, ViewportConfig, NavigationView, ViewFrameElement } from "../types/Config.types";
import { findClosestTransition, findMatchingTransitions, distanceBetweenTwoIndexes } from "../utils/Viewer3d.utils";

import { debounce } from "lodash-es";

import logo from "../assets/logo.png";

import "./Viewer3d.scss";
import { Defer } from "../utils/Defer";

interface RotateData {
    active: boolean;
    startY: number | undefined;
    startX: number | undefined;
    startIndex: number | undefined;
}

export interface Viewer3dApartmentAvailability {
    [key: string]: 1 | 2 | 3;
}

interface Viewer3dProps {
    views: View[];
    transitions: Transition[];
    setCurrentApartmentId: (f: (apartmentId: string | undefined) => string | undefined) => void;
    currentApartmentId: string | undefined;
    viewportConfig: ViewportConfig;
    navigation: NavigationView[];
    apartmentIds: { [key: string]: true };
    openApartment: (apartmentId: string) => void;
    hideNightSwitch?: boolean;
    hideOrientation?: boolean;
    hideElementsSwitch?: boolean;
    highlightApartmentId?: string;
    externalState: Viewer3dStateExternal;
    externalStateSet: (f: (state: Viewer3dStateExternal) => Viewer3dStateExternal) => Promise<void>;
    apartmentsAvailability: Viewer3dApartmentAvailability;
    upperHalfRotateInverse?: boolean;
    inverseAllMovements?: boolean;
}

export interface Viewer3dStateExternal {
    currentViewId: number | undefined;
    currentTransitionId: number | undefined;
    futureViewId: number | undefined;
    currentIndex: number;
    rotateActive: boolean;
    rotateStartIndex: number | undefined;
}

interface Viewer3dState {
    rotateViewToFrameIndex: number | undefined;
    isNight: boolean;
    viewerHeight: number;
    scale: number;
    visibleElements: boolean;
}

export default class Viewer3d extends React.Component<Viewer3dProps, Viewer3dState> {
    state: Viewer3dState = {
        rotateViewToFrameIndex: undefined,
        isNight: false,
        viewerHeight: 1200,
        scale: 1,
        visibleElements: true
    };

    private rotateData: RotateData = {
        active: false,
        startX: undefined,
        startY: undefined,
        startIndex: undefined
    };

    private imageFramesRef = React.createRef<HTMLDivElement>();

    private resizeWatcher = () => {
        this.calculateImageWrapperHeightDebounced();

        window.addEventListener("resize", this.resizeWatcher);
    };

    componentDidMount() {
        this.calculateImageWrapperHeight();
        window.addEventListener("resize", this.resizeWatcher);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWatcher);
    }

    get externalState() {
        return this.props.externalState;
    }

    render() {
        const frames: { day: JSX.Element[]; night: JSX.Element[] } = {
            day: [],
            night: []
        };

        const elements: JSX.Element[] = [];

        const transitions: { day: JSX.Element[]; night: JSX.Element[] } = { day: [], night: [] };

        this.props.views.forEach(view => {
            if (view.id === this.externalState.currentViewId || view.id === this.externalState.futureViewId) {
                view.frames.forEach((frame, frameIndex) => {
                    frames.day.push(
                        <img
                            key={`frame-${view.id}-${frameIndex}-day`}
                            src={frame.src_d}
                            className="viewer-3d__frame-img"
                            alt=""
                            style={{
                                opacity:
                                    this.externalState.currentTransitionId === undefined &&
                                    this.externalState.currentViewId === view.id &&
                                    this.externalState.currentIndex === frameIndex
                                        ? 1
                                        : 0
                            }}
                        />
                    );

                    frames.night.push(
                        <img
                            key={`frame-${view.id}-${frameIndex}-night`}
                            src={frame.src_n}
                            className="viewer-3d__frame-img"
                            alt=""
                            style={{
                                opacity:
                                    this.externalState.currentTransitionId === undefined &&
                                    this.externalState.currentViewId === view.id &&
                                    this.externalState.currentIndex === frameIndex
                                        ? 1
                                        : 0
                            }}
                        />
                    );

                    if (
                        this.externalState.currentTransitionId === undefined &&
                        view.id === this.externalState.currentViewId &&
                        frameIndex === this.externalState.currentIndex &&
                        !!frame.elements
                    ) {
                        frame.elements
                            .filter(
                                e =>
                                    e.action.type !== "Apartment" ||
                                    (e.action.type === "Apartment" && this.props.apartmentIds[e.action.viewid])
                            )
                            .forEach(element => {
                                elements.push(
                                    <polygon
                                        key={`${element.id}-${element.action.type}-${
                                            element.action.type === "Apartment" ? element.action.viewid : ""
                                        } `}
                                        className={`viewer-3d__element ${
                                            element.action.type === "Apartment" &&
                                            this.props.currentApartmentId === element.action.viewid
                                                ? "is-hover"
                                                : ""
                                        } ${
                                            element.action.type === "Apartment" &&
                                            this.props.highlightApartmentId === element.action.viewid
                                                ? "is-highlight"
                                                : ""
                                        } ${
                                            element.action.type === "Apartment" &&
                                            this.props.apartmentsAvailability[element.action.viewid] === 2
                                                ? "is-reserved"
                                                : ""
                                        }
                                        ${
                                            element.action.type === "Apartment" &&
                                            this.props.apartmentsAvailability[element.action.viewid] === 3
                                                ? "is-sold"
                                                : ""
                                        }`}
                                        points={element.path.map(p => `${p.x * 1600.0} ${p.y * 1200.0}`).join(", ")}
                                        onMouseDown={e => e.stopPropagation()}
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.onElementClick(element);
                                        }}
                                        onMouseOver={() => {
                                            if (element.action.type === "Apartment") {
                                                this.onApartmentMouseOver(element.action.viewid);
                                            }
                                        }}
                                        onMouseOut={() => {
                                            if (element.action.type === "Apartment") {
                                                this.onApartmentMouseOut(element.action.viewid);
                                            }
                                        }}
                                    />
                                );
                            });
                    }
                });
            }
        });

        this.props.transitions.forEach(transition => {
            transition.frames.forEach((frame, frameIndex) => {
                transitions.day.push(
                    <img
                        src={frame.src_d}
                        key={`transition-${transition.id}-${frameIndex}-day`}
                        className="viewer-3d__frame-img"
                        style={{
                            opacity:
                                this.externalState.currentTransitionId === transition.id &&
                                this.externalState.currentIndex === frameIndex
                                    ? 1
                                    : 0
                        }}
                    />
                );
                transitions.night.push(
                    <img
                        src={frame.src_n}
                        key={`transition-${transition.id}-${frameIndex}-night`}
                        className="viewer-3d__frame-img"
                        style={{
                            opacity:
                                this.externalState.currentTransitionId === transition.id &&
                                this.externalState.currentIndex === frameIndex
                                    ? 1
                                    : 0
                        }}
                    />
                );
            });
        });

        const viewerWidth =
            (this.state.viewerHeight * this.props.viewportConfig.width) / this.props.viewportConfig.height;

        return (
            <div
                className={`viewer-3d ${this.state.isNight ? "is-night" : ""} ${
                    this.state.visibleElements ? "" : "is-element-visibility-off"
                }`}
            >
                <img src={logo} className="viewer-3d__logo" />
                <div
                    className="viewer-3d__frames"
                    ref={this.imageFramesRef}
                    style={{ width: viewerWidth, transform: `scale(${this.state.scale})` }}
                >
                    <div className="viewer-3d__frames-group day">
                        {frames.day}
                        {transitions.day}
                    </div>
                    <div className="viewer-3d__frames-group night">
                        {frames.night}
                        {transitions.night}
                    </div>
                    <div
                        className="viewer-3d__clickable"
                        onMouseDown={e => this.rotateStart(e.clientX, e.clientY)}
                        onMouseMove={e => this.rotateMove(e.clientX)}
                        onMouseLeave={() => this.rotateStop()}
                        onMouseUp={() => this.rotateStop()}
                        onTouchStartCapture={event => this.onTouchStart(event)}
                        onTouchMove={event => {
                            // event.preventDefault();
                            this.onTouchMove(event);
                        }}
                        onTouchEnd={() => this.rotateStop()}
                    >
                        <svg className="viewer-3d__elements" viewBox="0 0 1600 1200">
                            {this.state.visibleElements ? elements : null}
                        </svg>
                    </div>
                </div>
                {!this.props.hideNightSwitch ? (
                    <div className="viewer-3d__day-night" onClick={() => this.toggleDayNight()}>
                        <div className="viewer-3d__day-night-icon left">wb_sunny</div>
                        <div className="viewer-3d__day-night-icon right">brightness_3</div>
                    </div>
                ) : null}

                {!this.props.hideElementsSwitch ? (
                    <div className="viewer-3d__visibility" onClick={() => this.toggleElementsVisibility()}>
                        <div className="viewer-3d__visibility-icon left">visibility</div>
                        <div className="viewer-3d__visibility-icon right">visibility_off</div>
                    </div>
                ) : null}

                {!this.props.hideOrientation ? (
                    <div className="viewer-3d__orientation">
                        <div
                            className="viewer-3d__orientation-circle"
                            style={{ transform: `rotate(${(this.externalState.currentIndex * 360) / 200}deg)` }}
                        >
                            <div className="viewer-3d__orientation-circle-label">N</div>
                        </div>
                    </div>
                ) : null}

                <div className="viewer-3d__buttons">
                    {this.props.navigation.map(n => (
                        <div
                            key={n.viewid}
                            className={`button ${n.viewid === this.externalState.currentViewId ? "is-active" : ""}`}
                            onClick={() => this.goToView(n.viewid)}
                        >
                            {n.label}
                        </div>
                    ))}
                </div>
                <div className="viewer-3d__controls">
                    <div className="button icon" onClick={() => this.scale(0.2)}>
                        add
                    </div>
                    <div className="button icon" onClick={() => this.scale(-0.2)}>
                        remove
                    </div>
                    <div className="button icon" onClick={() => this.skipFrames(-30)}>
                        keyboard_arrow_left
                    </div>
                    <div className="button icon" onClick={() => this.skipFrames(30)}>
                        keyboard_arrow_right
                    </div>
                </div>

                <div className="viewer-3d__support button">?</div>
            </div>
        );
    }

    private async skipFrames(frameCount: number) {
        if (this.externalState.currentViewId === undefined) {
            return;
        }

        const currentView = this.props.views.find(v => v.id === this.externalState.currentViewId);

        if (!currentView) {
            return;
        }

        let newFrameCount = this.externalState.currentIndex + frameCount;

        if (newFrameCount >= currentView.frames.length) {
            newFrameCount = newFrameCount - currentView.frames.length;
        }
        if (newFrameCount < 0) {
            newFrameCount = currentView.frames.length + newFrameCount;
        }

        try {
            await this.goToFrame(newFrameCount);
        } catch (e) {
            console.log(e);
        }
    }

    private scale(change: number) {
        let newScale = this.state.scale + change;

        if (newScale > 2) {
            newScale = 2;
        }
        if (newScale < 1) {
            newScale = 1;
        }

        if (this.state.scale !== newScale) {
            this.setState({ scale: newScale });
        }
    }

    private toggleDayNight() {
        this.setState(p => ({ isNight: !p.isNight }));
    }

    private toggleElementsVisibility() {
        this.setState(p => ({ visibleElements: !p.visibleElements }));
    }

    private rotateStart(x: number, y: number) {
        if (this.externalState.currentTransitionId !== undefined) {
            return;
        }
        this.rotateData.active = true;
        this.rotateData.startIndex = this.externalState.currentIndex;
        this.rotateData.startX = x;
        this.rotateData.startY = y;
        this.setState({ rotateViewToFrameIndex: undefined });
        this.props.externalStateSet(p => ({
            ...p,
            rotateActive: true,
            rotateStartIndex: this.externalState.currentIndex
        }));
    }

    private rotateMove(x: number) {
        if (!this.rotateData.active || this.externalState.currentViewId === undefined) {
            return;
        }

        const view = this.props.views.find(e => e.id === this.externalState.currentViewId);

        if (!view) {
            return;
        }

        const max = view.frames.length;

        if (max < 1) {
            return;
        }

        // const max = 200;

        let direction = 1;

        if (this.props.upperHalfRotateInverse) {
            const e = this.imageFramesRef.current;

            if (e) {
                const height = e.offsetHeight;
                if (this.rotateData.startY !== undefined && this.rotateData.startY < height / 2) {
                    direction = -1;
                }
            }
        }

        if (this.props.inverseAllMovements) {
            direction = direction * -1;
        }

        const factor = (360 * 6) / max;

        const deltaX = (-x + (this.rotateData.startX || 0)) * direction;
        const deltaIndex = Math.round(deltaX / factor);

        let newIndex = ((this.rotateData.startIndex || 0) + deltaIndex) % max;
        newIndex = newIndex < 0 ? newIndex + max : newIndex;

        // this.setState({ currentIndex: newIndex });
        this.props.externalStateSet(p => ({ ...p, currentIndex: newIndex }));
    }

    private rotateStop() {
        this.rotateData.active = false;
        this.rotateData.startIndex = undefined;
        this.rotateData.startX = undefined;
        this.rotateData.startY = undefined;
        this.props.externalStateSet(p => ({ ...p, rotateActive: false, rotateStartIndex: undefined }));
    }

    private onElementClick(element: ViewFrameElement) {
        if (element.action.type === "View") {
            // this.goToView(element.action.viewId);
        }

        if (element.action.type === "Apartment") {
            console.log("show apartment ", element.action.viewid);
            this.props.openApartment(element.action.viewid);
        }
    }

    private onTouchStart(event: React.TouchEvent<HTMLDivElement>) {
        if (event.touches.length !== 1) {
            console.log("only one touch supported");
        } else {
            this.rotateStart(event.touches[0].clientX, event.touches[0].clientY);
        }
    }

    private onTouchMove(event: React.TouchEvent<HTMLDivElement>) {
        if (event.touches.length === 1) {
            this.rotateMove(event.touches[0].clientX);
        } else {
            console.log("only one touch supported");
        }
    }

    private onApartmentMouseOver(apartmentId: string) {
        this.props.setCurrentApartmentId(() => apartmentId);
    }

    private onApartmentMouseOut(apartmentId: string) {
        this.props.setCurrentApartmentId(p => (p === apartmentId ? undefined : p));
    }

    private calculateImageWrapperHeightDebounced: () => void = debounce(() => this.calculateImageWrapperHeight(), 500);

    private calculateImageWrapperHeight() {
        const imageWrapper = this.imageFramesRef.current;
        if (!!imageWrapper) {
            this.setState(p => ({ viewerHeight: imageWrapper.offsetHeight }));
        }
    }

    private async goToFrame(frameId: number): Promise<void> {
        if (this.externalState.currentTransitionId !== undefined) {
            return;
        }

        const currentView = this.props.views.find(e => e.id === this.externalState.currentViewId);

        if (!currentView) {
            return;
        }

        frameId = frameId < 0 ? 0 : frameId >= currentView.frames.length ? currentView.frames.length - 1 : frameId;

        await new Promise(res => this.setState({ rotateViewToFrameIndex: frameId }, () => res()));

        return this.animateFrameToTarget();
    }

    private async animateFrameToTarget(): Promise<void> {
        if (this.state.rotateViewToFrameIndex === undefined) {
            console.log("error");
            throw new Error("CannotAnimateFrameToIndexNoTargetIndex");
        }

        if (this.externalState.currentIndex === this.state.rotateViewToFrameIndex) {
            return;
        }

        const currentView = this.props.views.find(e => e.id === this.externalState.currentViewId);

        if (!currentView) {
            console.log("error");
            throw new Error("CannotAniamteFrameToIndexNoView");
        }

        const distance = distanceBetweenTwoIndexes(
            this.externalState.currentIndex,
            this.state.rotateViewToFrameIndex,
            currentView.frames.length
        );

        if (distance === 0) {
            return;
        }

        // await new Promise(res =>
        //     this.setState(
        //         p => {
        //             let newIndex = p.currentIndex + (distance < 0 ? -1 : 1);
        //             if (newIndex < 0) {
        //                 newIndex = currentView.frames.length - 1;
        //             }
        //             if (newIndex >= currentView.frames.length) {
        //                 newIndex = 0;
        //             }
        //             return {
        //                 currentIndex: newIndex
        //             };
        //         },
        //         () => res()
        //     )
        // );

        await this.props.externalStateSet(p => {
            let newIndex = p.currentIndex + (distance < 0 ? -1 : 1);
            if (newIndex < 0) {
                newIndex = currentView.frames.length - 1;
            }
            if (newIndex >= currentView.frames.length) {
                newIndex = 0;
            }
            return {
                ...p,
                currentIndex: newIndex
            };
        });

        await Defer(20);

        return this.animateFrameToTarget();
    }

    private async goToView(newViewId: number) {
        if (this.externalState.currentViewId === undefined) {
            return;
        }

        if (this.externalState.currentTransitionId !== undefined || this.externalState.futureViewId !== undefined) {
            return;
        }

        if (this.externalState.currentViewId === newViewId) {
            return;
        }

        if (this.state.rotateViewToFrameIndex !== undefined) {
            await new Promise(res => this.setState({ rotateViewToFrameIndex: undefined }, () => res()));
            await Defer(100);
            // return;
        }

        const currentView = this.props.views.find(e => e.id === this.externalState.currentViewId);
        const newView = this.props.views.find(e => e.id === newViewId);

        if (!currentView || !newView) {
            return;
        }

        const matchingTransitions = findMatchingTransitions(currentView, newView, this.props.transitions);

        if (matchingTransitions.length === 0) {
            // this.setState({ currentViewId: newViewId, currentIndex: 0, currentTransitionId: undefined });
            this.props.externalStateSet(p => ({
                ...p,
                currentViewId: newViewId,
                currentIndex: 0,
                currentTransitionId: undefined
            }));
            return;
        }

        const closestTransition = findClosestTransition(
            currentView,
            matchingTransitions,
            this.externalState.currentIndex
        );

        try {
            await this.goToFrame(
                closestTransition.from.id === currentView.id
                    ? closestTransition.from.frameid
                    : closestTransition.to.frameid
            );
        } catch (e) {
            console.log(e);
            return;
        }

        // await new Promise(res =>
        //     this.setState(
        //         {
        //             currentTransitionId: closestTransition.id,
        //             futureViewId: newViewId,
        //             currentIndex: closestTransition.from.id === currentView.id ? 0 : closestTransition.frames.length - 1
        //         },
        //         () => res()
        //     )
        // );

        await this.props.externalStateSet(p => ({
            ...p,
            currentTransitionId: closestTransition.id,
            futureViewId: newViewId,
            currentIndex: closestTransition.from.id === currentView.id ? 0 : closestTransition.frames.length - 1
        }));

        await this.proceedTransition();
    }

    private async proceedTransition(): Promise<void> {
        if (this.externalState.currentTransitionId === undefined) {
            return;
        }

        const transition = this.props.transitions.find(e => e.id === this.externalState.currentTransitionId);

        if (!transition) {
            // this.setState(p => ({
            //     currentViewId:
            //         p.futureViewId !== undefined
            //             ? p.futureViewId
            //             : p.currentViewId !== undefined
            //             ? p.currentViewId
            //             : this.props.views[0].id,
            //     currentIndex: 0
            // }));
            this.props.externalStateSet(p => ({
                ...p,
                currentViewId:
                    p.futureViewId !== undefined
                        ? p.futureViewId
                        : p.currentViewId !== undefined
                        ? p.currentViewId
                        : this.props.views[0].id,
                currentIndex: 0
            }));
            return;
        }

        // this.setState(p => {
        //     const newIndex = p.currentIndex + (transition.to.id === p.futureViewId ? 1 : -1);

        //     if (newIndex < 0 || newIndex >= transition.frames.length) {
        //         return {
        //             ...p,
        //             currentViewId: p.futureViewId,
        //             currentTransitionId: undefined,
        //             currentIndex: transition.to.id === p.futureViewId ? transition.to.frameid : transition.from.frameid,
        //             futureViewId: undefined
        //         };
        //     }

        //     return {
        //         ...p,
        //         currentIndex: newIndex
        //     };
        // });

        this.props.externalStateSet(p => {
            const newIndex = p.currentIndex + (transition.to.id === p.futureViewId ? 1 : -1);

            if (newIndex < 0 || newIndex >= transition.frames.length) {
                return {
                    ...p,
                    currentViewId: p.futureViewId,
                    currentTransitionId: undefined,
                    currentIndex: transition.to.id === p.futureViewId ? transition.to.frameid : transition.from.frameid,
                    futureViewId: undefined
                };
            }

            return {
                ...p,
                currentIndex: newIndex
            };
        });

        await Defer(20);

        return this.proceedTransition();
    }
}
