import React from "react";
import { AppConfig } from "../types/Config.types";

import Viewer3d, { Viewer3dStateExternal, Viewer3dApartmentAvailability } from "./Viewer3d";

import "./Apartments.scss";
import { isBetween } from "../utils/Viewer3d.utils";

import ApartmentFilter from "./ApartmentFilter";
import ApartmentList from "./ApartmentList";
import ApartmentView from "./Apartment";
import Nav from "./Nav";

export interface Filters {
    showOnlyVisible: boolean;
    balcony: boolean;
    terrace: boolean;
    garden: boolean;
    available: boolean;
    reserved: boolean;
    sold: boolean;
    areaMin: number | undefined;
    areaMax: number | undefined;
    floorMin: number | undefined;
    floorMax: number | undefined;
    roomCountMin: number | undefined;
    roomCountMax: number | undefined;
}

interface ApartmentsProps {
    config: AppConfig;
}

interface ApartmentsState {
    apartmentId: string | undefined;
    currentApartmentId: string | undefined;
    filters: Filters;
    filtersByPropsBoolean: number[];
    isCards: boolean;
    isNavOpen: boolean;
    externalState: Viewer3dStateExternal;
}

export default class Apartments extends React.Component<ApartmentsProps, ApartmentsState> {
    state: ApartmentsState = {
        apartmentId: undefined,
        currentApartmentId: undefined,
        filtersByPropsBoolean: [],
        filters: {
            showOnlyVisible: false,
            balcony: false,
            terrace: false,
            garden: false,
            available: false,
            reserved: false,
            sold: false,
            areaMax: undefined,
            areaMin: undefined,
            floorMax: undefined,
            floorMin: undefined,
            roomCountMax: undefined,
            roomCountMin: undefined
        },
        externalState: {
            currentViewId: this.props.config.apartmentViews[0].viewid,
            currentTransitionId: undefined,
            futureViewId: undefined,
            currentIndex: this.props.config.apartmentViews[0].startFrame,
            rotateActive: false,
            rotateStartIndex: undefined
        },
        isCards: true,
        isNavOpen: false
    };

    private filterInit: Filters = {
        showOnlyVisible: false,
        balcony: false,
        terrace: false,
        garden: false,
        available: false,
        reserved: false,
        sold: false,
        areaMin: undefined,
        areaMax: undefined,
        floorMax: undefined,
        floorMin: undefined,
        roomCountMin: undefined,
        roomCountMax: undefined
    };

    componentDidMount() {
        this.props.config.apartments.forEach(a => {
            if (!this.filterInit.areaMin || a.apartmentArea < this.filterInit.areaMin) {
                this.filterInit.areaMin = Math.floor(a.apartmentArea);
            }
            if (!this.filterInit.areaMax || a.apartmentArea > this.filterInit.areaMax) {
                this.filterInit.areaMax = Math.ceil(a.apartmentArea);
            }

            if (!this.filterInit.floorMin || a.floor < this.filterInit.floorMin) {
                this.filterInit.floorMin = a.floor;
            }
            if (!this.filterInit.floorMax || a.floor > this.filterInit.floorMax) {
                this.filterInit.floorMax = a.floor;
            }

            if (!this.filterInit.roomCountMin || a.roomCount < this.filterInit.roomCountMin) {
                this.filterInit.roomCountMin = a.roomCount;
            }
            if (!this.filterInit.roomCountMax || a.roomCount > this.filterInit.roomCountMax) {
                this.filterInit.roomCountMax = a.roomCount;
            }
        });

        this.setState(() => ({ filters: this.filterInit }));
    }

    render() {
        const apartment = this.props.config.apartments.find(a => a.id === this.state.apartmentId);
        const apartmentView = this.props.config.apartmentViews.find(
            v => v.viewid === this.state.externalState.currentViewId
        );
        return (
            <div className="apartments">
                <div className="apartments__viewer">
                    <Viewer3d
                        views={this.props.config.views}
                        transitions={this.props.config.transitions}
                        setCurrentApartmentId={f =>
                            this.setState(p => ({ currentApartmentId: f(p.currentApartmentId) }))
                        }
                        viewportConfig={this.props.config.viewport}
                        navigation={this.props.config.apartmentViews}
                        currentApartmentId={this.state.currentApartmentId}
                        apartmentIds={this.apartmentIds}
                        openApartment={apartmentId => this.setState({ apartmentId: apartmentId })}
                        externalState={this.state.externalState}
                        externalStateSet={f =>
                            new Promise(res =>
                                this.setState(
                                    p => ({ externalState: f(p.externalState) }),
                                    () => res()
                                )
                            )
                        }
                        apartmentsAvailability={this.apartmentsAvailability}
                    />
                </div>

                <div className="apartments__aside">
                    <div className="apartments__aside-content">
                        <div className="apartments__aside-padding">
                            <div className="apartments__aside-header">
                                <div className="apartments__aside-header-title">Mieszkania</div>
                                <div
                                    className="apartments__aside-header-menu"
                                    onClick={() => this.setState({ isNavOpen: true })}
                                >
                                    menu
                                </div>
                            </div>

                            <div className="apartments__aside-building">{apartmentView ? apartmentView.title : ""}</div>
                            <div className="apartments__aside-row">
                                <div className="apartments__aside-available-apartments">
                                    <b>75</b> wolnych mieszkań
                                </div>

                                <div
                                    className="apartments__aside-change-view-button"
                                    onClick={() => this.setState(p => ({ isCards: !p.isCards }))}
                                >
                                    <div className="apartments__aside-change-view-button-icon">
                                        {this.state.isCards ? "view_column" : "view_module"}
                                    </div>
                                    <div className="apartments__aside-change-view-button-label">
                                        {this.state.isCards ? "Widok listy" : "Widok kart"}
                                    </div>
                                </div>
                            </div>
                            <div className="apartments__aside-filters">
                                <ApartmentFilter
                                    init={this.filterInit}
                                    current={this.state.filters}
                                    changeFilter={(filter, value) =>
                                        this.setState(p => ({ filters: { ...p.filters, [filter]: value } }))
                                    }
                                    filtersByPropsBooleanList={this.filtersByPropsBooleanList}
                                    filtersByPropsBoolean={this.state.filtersByPropsBoolean}
                                    filtersByPropsBooleanChange={f => this.filtersByPropsBooleanChange(f)}
                                />
                            </div>
                        </div>

                        <ApartmentList
                            apartments={this.apartmentsList}
                            openApartment={id => this.setState({ apartmentId: id })}
                            onApartmentOut={id => this.onApartmentMouseOut(id)}
                            onApartmentOver={id => this.onApartmentMouseOver(id)}
                            currentOverApartment={this.state.currentApartmentId}
                            isCards={this.state.isCards}
                        />
                    </div>

                    {this.state.isNavOpen ? <Nav close={() => this.setState({ isNavOpen: false })} /> : null}
                </div>

                {apartment && this.state.apartmentId ? (
                    <ApartmentView
                        apartmentId={this.state.apartmentId}
                        apartment={apartment}
                        close={() => this.setState({ apartmentId: undefined })}
                        config={this.props.config}
                        changeApartment={apartmentId => this.setState({ apartmentId: apartmentId })}
                    />
                ) : null}
            </div>
        );
    }

    get apartmentsAvailability(): Viewer3dApartmentAvailability {
        return this.filteredApartments.reduce((o, a) => {
            o[a.id] = a.availability;
            return o;
        }, {} as Viewer3dApartmentAvailability);
    }

    get apartmentIds() {
        return this.filteredApartments.reduce((o, a) => {
            o[a.id] = true;
            return o;
        }, {} as { [key: string]: true });
    }

    get currentView() {
        return this.props.config.views.find(v => v.id === this.state.externalState.currentViewId);
    }

    get apartmentsList() {
        const currentView = this.currentView;
        const currentFrame = currentView
            ? this.state.externalState.rotateActive && this.state.externalState.rotateStartIndex !== undefined
                ? currentView.frames[this.state.externalState.rotateStartIndex]
                : currentView.frames[this.state.externalState.currentIndex]
            : undefined;

        const apartmentIdsForCurrentFrame =
            !currentFrame || !currentFrame.elements
                ? {}
                : currentFrame.elements.reduce((o, e) => {
                      if (e.action.type === "Apartment") {
                          o[e.action.viewid] = true;
                      }
                      return o;
                  }, {} as { [key: string]: true });

        return this.filteredApartments.filter(
            e => !this.state.filters.showOnlyVisible || apartmentIdsForCurrentFrame[e.id]
        );
    }

    get filteredApartments() {
        // return this.props.config.apartments;
        const currentView = this.props.config.views.find(v => v.id === this.state.externalState.currentViewId);

        const apartmentIdsForCurrentView = !currentView
            ? {}
            : currentView.frames.reduce((ids, frame) => {
                  if (frame.elements) {
                      frame.elements.forEach(e => {
                          if (e.action.type === "Apartment") {
                              ids[e.action.viewid] = true;
                          }
                      });
                  }
                  return ids;
              }, {} as { [key: string]: true });

        // const currentFrame = currentView
        //     ? // ? this.state.externalState.rotateActive && this.state.externalState.rotateStartIndex !== undefined
        //       //     ? currentView.frames[this.state.externalState.rotateStartIndex]
        //       currentView.frames[this.state.externalState.currentIndex]
        //     : undefined;

        // const apartmentIdsForCurrentFrame =
        //     !currentFrame || !currentFrame.elements
        //         ? {}
        //         : currentFrame.elements.reduce((o, e) => {
        //               if (e.action.type === "Apartment") {
        //                   o[e.action.viewid] = true;
        //               }
        //               return o;
        //           }, {} as { [key: string]: true });

        return this.props.config.apartments.filter(
            e =>
                apartmentIdsForCurrentView[e.id] &&
                (!this.state.filters.available || e.availability === 1) &&
                (!this.state.filters.reserved || e.availability === 2) &&
                (!this.state.filters.sold || e.availability === 3) &&
                this.state.filtersByPropsBoolean.reduce((result, filter) => {
                    if (!result) {
                        return false;
                    }
                    const prop = e.props.find(p => p.id === filter);
                    if (!prop) {
                        return false;
                    }

                    if (!prop.value) {
                        return false;
                    }

                    return true;
                }, true as boolean) &&
                isBetween(e.apartmentArea, this.state.filters.areaMin, this.state.filters.areaMax) &&
                isBetween(e.floor, this.state.filters.floorMin, this.state.filters.floorMax) &&
                isBetween(e.roomCount, this.state.filters.roomCountMin, this.state.filters.roomCountMax)
            // &&(!this.state.filters.showOnlyVisible || apartmentIdsForCurrentFrame[e.id])
        );
    }

    get filtersByPropsBooleanList() {
        return this.props.config.apartmentPropsDefinitions.filter(a => a.filter);
    }

    private onApartmentMouseOver(apartmentId: string) {
        this.setState(p => ({ currentApartmentId: apartmentId }));
    }

    private onApartmentMouseOut(apartmentId: string) {
        this.setState(p => (p.currentApartmentId === apartmentId ? { currentApartmentId: undefined } : { ...p }));
    }

    private filtersByPropsBooleanChange(filter: number) {
        this.setState(p => {
            const index = p.filtersByPropsBoolean.indexOf(filter);

            const t = p.filtersByPropsBoolean;

            if (index === -1) {
                t.push(filter);
            } else {
                t.splice(index, 1);
            }

            return {
                filtersByPropsBoolean: t
            };
        });
    }
}
